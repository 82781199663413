import React from 'react'
import { Input, Alert } from 'antd';

const { TextArea } = Input

interface Props {
    onChange: (text: string) => void
    value?: any
}

function MessageEditor(props: Props) {
    return (
        <div className="mini-create-message">
            { props.value == "undefined" ? (
                <TextArea onChange={(e) => props.onChange(e.target.value)} rows={10} placeholder="Write your message here" />
            ) : (
                <TextArea value={props.value} onChange={(e) => props.onChange(e.target.value)} rows={10} placeholder="Write your message here" />
            )}
        </div>
    )
}

export default MessageEditor
