import React from "react";
import { Input, Alert, Form } from "antd";

const { TextArea } = Input;

const initMessagePst = {
  title: "",
};

interface Message {
  title: string;
}

interface Props {
  onChange: ({ }: Message) => void;
  post: Message;
}

function EditableMessageEditor(props: Props) {
  const { post } = props;
  const [obj, setObj] = React.useState<Message>(post || initMessagePst);

  React.useEffect(() => {
    setObj(post);
  }, [post]);

  React.useEffect(() => {
    props.onChange(obj);
  }, [obj]);

  return (
    <>
      <Form name="writing-piece-form">
        <Form.Item>
          <TextArea
            value={obj.title}
            onChange={(e) => setObj({ ...obj, title: e.target.value })}
            rows={6}
            placeholder="Write your message here"
          />
        </Form.Item>
      </Form>
    </>
  );
}

export default EditableMessageEditor;
