import React from 'react'
import { Row, Typography, Col, Icon } from 'antd'
import { Link, useHistory } from 'react-router-dom'
import { formatTime } from '../../../utils/helpers'
import ProfileAvatar from '../Avatars/ProfileAvatar'
import { partition } from "lodash";

const { Text } = Typography
const MAX_LENGTH = 40

function PostBlock(props: any) {

    var userObj = props.user;
    const link = `/post/${props.post_id}`
    const date = formatTime(props.created_at);

    let splitTitle = props.title.substring(0, 150)

    //Identifies the Level 1 & Level 2 & 3 Comments
    const [nested, main] = partition(props.comments, (o) => o.parent_id);

    return (
        <div className="post-block" key={props.index + "_post"}>
            {props.post_type === "WP" ? (
                <>
                    <Row>
                        <a href={link}><h2 className="post-title">{props.title}</h2></a>
                        <div className="post-description">
                            {props.summary === null || props.summary === undefined ? (
                                <p></p>
                            ) : (
                                <div>
                                    {props.summary.length > MAX_LENGTH || props.summary === undefined ? (
                                        <a href={link}><p className="">{props.summary}<a className="link" href={link}> read more</a></p></a>
                                    ) :
                                        <p>{props.summary}</p>
                                    }
                                </div>
                            )}
                        </div>
                    </Row>
                    <Row type="flex" align="bottom" justify="space-between" className="action-row">
                        {props.user ? (
                            <Col>
                                <ProfileAvatar
                                    title={props.user.name}
                                    subtitle={date}
                                    img={props.user.profile_pic}
                                    size={36}
                                    userRole={props.user.user_role}
                                />
                            </Col>
                        ) : (
                            <Col>
                                <span>{date}</span>
                            </Col>
                        )}
                        <Col>
                            <a href={link} className="feed-critique-font-type">{main.length} Critiques {nested.length} Replies</a>
                            {/* <Link to={link}>{main.length} Critiques {nested.length} Replies</Link> */}
                        </Col>
                    </Row>
                </>
            ) : (
                <>
                    <Row type="flex" align="bottom" justify="space-between" className="action-row">
                        {props.user && props.group ? (
                            <Col>
                                <ProfileAvatar
                                    title={props.user.name + " posted a " + "<a class='feed-message-font-type'>" + "message" + "</a>"}
                                    subtitle={date}
                                    img={props.user.profile_pic}
                                    size={36}
                                    userRole={props.user.user_role}
                                    postType={"Message"}
                                />
                            </Col>
                        ) : (
                            <Col>
                                <span>{date}</span>
                            </Col>
                        )}
                    </Row>
                    <Row>
                        <br />
                        {props.title.length > MAX_LENGTH ? (
                            <a href={link} className="post-title">{splitTitle}<a className="link" href={link}> read more</a></a>
                        ) : <a href={link}><h2 className="post-title">{props.title}</h2></a>
                        }
                        {/* <a href={link}><h2 className="post-title">{props.title}</h2></a> */}
                        <div style={{ textAlign: "right" }}>
                            <Col>
                                <a href={link} className="feed-critique-font-type">{main.length} Replies</a>
                                {/* <Link to={link}>{main.length} Replies</Link> */}
                            </Col>
                        </div>
                    </Row>
                </>
            )}
        </div>
    );
}

export default PostBlock
