import React, { useState } from "react";
import { connect } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { Tabs, Button, Row, Select, Tooltip, notification, Typography, Popover, Modal } from "antd";
import { filter } from "lodash";

import MessageEditor from "../../snippets/editors/MessageEditor";
import WritingPieceEditor from "../../snippets/editors/WritingPieceEditor";
import { invokeCreatePost } from "../../../actions/posts";
import { getGenre } from "../../../actions/posts"
import { fetchUserSubscription } from "../../../actions/user";

const { Text } = Typography;

const { TabPane } = Tabs;

const initPost = {
  title: "",
  post: "",
  feedback: "",
  summary: "",
  genre_id: 1,
  word_count: ""
};

interface CreatePostProps {
  invokeCreatePost: (responseBody: any) => any;
  groups: any;
  loading: boolean;
  // pwa_post?: string;
  getGenre: () => any;
  fetchUserSubscription: () => any;
}

interface State {
  draftDetails: any;
}

function CreatePostContainer(props: CreatePostProps) {
  const history = useHistory();
  const location = useLocation<State>();
  const [tab, setTab] = React.useState("1");
  const [wp, setWp] = React.useState(initPost);
  const [msg, setMsg] = React.useState("");
  const [group, setGroup] = useState<any>();
  const [errors, setErrors] = useState<Array<string>>([]);
  const [wordCount, setWordCount] = useState("");

  //Drafts
  const [initState, setInitState] = useState(initPost);
  const [draftParams, setDraftSubParams] = React.useState({
    title: "",
    post: "",
    summary: "",
    feedback: "",
    genre_id: 1,
    id: "",
    word_count: ""
  });
  const [userSubscription, setUserSubscription] = useState({
    id: "",
    isExpired: "",
    isValid: "",
    plan: "",
    price: "",
    subscribed_date: "",
    subscription_period: "",
    user_id: "",
    user_subscription_id: "",
  });

  const handleSubmit = (e: any) => {
    let params =
      tab == "1"
        ? {
          title: wp.title,
          post: wp.post,
          summary: wp.summary,
          group_id: group,
          genre_id: wp.genre_id,
          feedback: wp.feedback,
          post_type_id: 1,
          word_count: wp.word_count
          // pwa_post: wp.pwa_post
        }
        : {
          title: msg,
          post: "",
          summary: "",
          feedback: "",
          group_id: group,
          post_type_id: 2,
          // pwa_post: msg
          //genre deleted 
        };

    if (initState.title.length > 0) {
      //Removes posted WP drafts from the main twp-draft array
      const drfts = JSON.parse(localStorage.getItem("twp-drafts") || "")
      const unPostedDrafts = filter(drfts, (a) => a.id != draftParams.id);
      const parsed = unPostedDrafts ? JSON.stringify(unPostedDrafts || "") : "";
      localStorage.setItem("twp-drafts", parsed);
    }

    const isvalid = validate();

    if (isvalid && !props.loading) {
      const submit = props.invokeCreatePost(params);
      submit.then((id: any) => {
        if (id) {
          if (tab == "1") {
            setTimeout(() => assignementsNotifiction("info"), 1500);
          }
          history.push(`post/${id}`);
        } else {
          Modal.error({
            title: "Couldn't save post",
            content: (
              <div>
                <h4>Please make sure your piece does not contain emojis. Also try copy-and-pasting from a plain text word processor which may remove formatting that interferes with our editor. If you continue to have issues, contact our help desk.</h4>
                <a target="_blank" href='https://thewritepractice.zendesk.com/hc/en-us/requests/newhttps://www.atticus.io/troubleshooting-synching-errors/' rel="noopener noreferrer">
                  https://thewritepractice.zendesk.com/hc/en-us/requests/new
                </a>
              </div>
            )
          });
        }
        // if (id) history.push(`post/${id}`);
        // else message.info(`Couldn't save post`);
      });
    }
  };

  const validate = () => {
    setErrors([]); //Reset validation to validate again.

    let errs: Array<string> = [];
    const text = wp.post.replace(/<[^>]*>/g, "");


    //VALIDATE
    if (tab == "1") {

      if (!wp.title)
        errs.push("You need to enter a title for your writing piece");

      if (text.trim().split(/\s+/).length < 2) errs.push("Your writing piece is empty");

      if (text.trim().split(/\s+/).length > parseInt(wordCount))
        errs.push(
          "Your writing piece is too long. You cannot post anymore than " +
          wordCount +
          " words"
        );

      if (!group) errs.push("Select a group to push the post to");

      let validateWPGroup = filter(props.groups, { id: group });
      validateWPGroup.map((x) => {
        if (x.group_types.length != 2) {
          x.group_types.map((y) => {
            if (y.group_type != "WP") {
              errs.push("Writing Pieces cannot be posted in the selected group")
            }
          })
        }
      })

    } else {
      if (!msg) errs.push("You cannot leave message as empty");
      if (msg.length < 2) errs.push("Your message is empty");
      if (!group) errs.push("Select a group to push the post to");

      let validateMessageGroup = filter(props.groups, { id: group });
      validateMessageGroup.map((x) => {
        x.group_types.map((y) => {
          if (x.group_types.length != 2) {
            if (y.group_type != "Message") {
              errs.push("Messages cannot be posted in the selected group")
            }
          }
        })
      })

    }
    // Set errors
    setErrors(errs);

    // Return validation
    if (errs.length < 1) return true;

    return false;
  };

  //Settings the word count
  React.useEffect(() => {
    const cs = filter(props.groups, (c) => c.id === group);
    const word = cs.map((a) => {
      setWordCount(a.word_count);
    });
  });

  //Tracking if the route is from the Drafts container
  React.useEffect(() => {
    if (location.state) {
      setDraftSubParams(location.state.draftDetails);
    }
    openNotificationWithIcon("info");
  }, [location]);

  //Drafts initial values
  React.useEffect(() => {
    if (location.state) {
      setInitState({
        title: draftParams.title + "",
        post: draftParams.post + "",
        genre_id: draftParams.genre_id,
        feedback: draftParams.feedback + "",
        summary: draftParams.summary + "",
        word_count: draftParams.word_count + ""
      });
    }
  }, [draftParams]);

  //Select group Notification
  const openNotificationWithIcon = (type) => {
    notification[type]({
      message: "Choose A Group",
      description:
        "Select the necessary Group from the dropdown below to get the word limit ",
    });
  };

  //Assignment Alert
  const assignementsNotifiction = (type) => {
    notification[type]({
      message: "New Assignment",
      description:
        "Now that you’ve posted your writing piece, it’s time to critique three pieces by other writers. Check the assignment box for your critique assignment. Happy critiquing!",
      duration: 15,
    });
  };

  //get all Subscriptions
  const getUserSubscriptions = async () => {
    const subs = await props.fetchUserSubscription();
    setUserSubscription(subs);
    return subs;
  };

  React.useEffect(() => {
    getUserSubscriptions();
  }, []);

  //Subscription Expired Popover over Submit Button
  const content = (
    <div>
      <Text mark>Please renew your subscription</Text>
    </div>
  );

  return (
    <div className="create-post">
      <div className="section">
        <div className="area">
          <Row type="flex" justify="space-between" align="middle">
            <h2>Create post</h2>
          </Row>
          <Tabs
            defaultActiveKey="1"
            animated={false}
            onChange={setTab}
            onTabClick={() => setErrors([])}
            tabBarExtraContent={
              <>
                <Tooltip title="Select Group">
                  <Select
                    style={{ minWidth: 300 }}
                    onChange={(v) => setGroup(parseInt(v.toString()))}
                    placeholder="Select a group"
                  >
                    {props.groups.map((grp, index) =>
                      tab === "1" ? (
                        grp.group_types.map((a, i) => (
                          a.group_type == "WP" ? (
                            <Select.Option key={"_grp " + index} value={grp.id}>
                              {grp.group_name}
                            </Select.Option>
                          ) : null
                        ))

                      ) : grp.group_types.map((a, i) => (
                        a.group_type == "Message" ? (
                          <Select.Option key={"_grp " + index} value={grp.id}>
                            {grp.group_name}
                          </Select.Option>
                        ) : null
                      ))
                    )}
                  </Select>
                </Tooltip>
              </>
            }
          >
            <div></div>
            <TabPane tab="Writing piece" key="1">
              {location.state ? (
                <WritingPieceEditor
                  onChange={setWp}
                  mini={true}
                  word_count={wordCount}
                  draftPost={true}
                  draftId={draftParams.id}
                  post={initState}
                />
              ) : (
                <WritingPieceEditor
                  onChange={setWp}
                  mini={true}
                  draftPost={false}
                  word_count={wordCount}
                />
              )}
            </TabPane>
            <TabPane tab="Message" key="2">
              <MessageEditor value={msg} onChange={setMsg} />
            </TabPane>
          </Tabs>
          {errors.length > 0 ? (
            <Row className="inner">
              {errors.map((err, i) => (
                <div key={`err_${i}`} style={{ color: "red" }}>
                  <b>* {err}</b>
                </div>
              ))}
            </Row>
          ) : null}
          <br />
          <Row type="flex">
            {userSubscription.isExpired === "true" ? (
              <Popover content={content} title="Subscription Expired" trigger="hover">
                <Button
                  size="large"
                  type="primary"
                  onClick={handleSubmit}
                  loading={props.loading}
                >
                  Submit
                </Button>
              </Popover>
            ) : (
              <Button
                size="large"
                type="primary"
                onClick={handleSubmit}
                loading={props.loading}
              >
                Submit
              </Button>
            )}

          </Row>
        </div>
      </div>
    </div>
  );
}

const mapStP = (state: any) => ({
  groups: state.groups.list,
  loading: state.posts.loading,
});

const Actions = {
  invokeCreatePost,
  getGenre,
  fetchUserSubscription
};

export default connect(mapStP, Actions)(CreatePostContainer);
