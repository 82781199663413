import React, { useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { useHistory, useLocation } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";

import {
  Form,
  Input,
  Alert,
  Button,
  Row,
  Icon,
  notification,
  message,
} from "antd";

import { Map } from "immutable";

// Sagas
import { signUp } from "../../../actions/auth";
import { verifyPromoCode } from "../../../actions/user";
import axios from "axios";

interface SignUpProps {
  // Properties
  token: string;
  form: any;
  // Methods
  signUp: (account: Account) => any;
  verifyPromoCode: (promo_code: string) => any;
}

interface NotificationProps {
  message: string;
  description: string;
}

type Account = {
  first_name: string;
  last_name: string;
  email: string;
  password: string;
  user_role_id: number;
  [key: string]: any;
};

const emailrule = [
  { required: true, message: "Please input email" },
  { type: "email", message: "Enter valid email" },
];
const rule = [{ required: true, message: "This field cannot be empty" }];

function SignUp(props: SignUpProps) {
  const history = useHistory();
  const location = useLocation();
  const [errors, setErrors] = useState<Array<string>>([]);
  const [isRegisterButtonVisible, setRegisterButtonVisible] = useState(false);
  // const [promoValidated, setPromoCodeValidation] = useState("");
  // const [promoMessage, setPromoMessage] = useState("");
  const [loading, toggleLoading] = useState(false);
  const [linkPromoCode, setPromoCode] = useState("");
  const [userPlan, setUserPlan] = useState("");

  let isPromo;
  var promoCode;

  React.useEffect(() => {
    isPromo = location.search; //capture the query params
    promoCode = new URLSearchParams(isPromo).get("promo_code") || "";
    promoCode.slice(0, 9);
    setPromoCode(promoCode);
    if (promoCode) {
      toggleLoading(true);
    }
  }, [isPromo]);

  React.useEffect(() => {
    let fetchSubscriptionPlan = location.search;
    let searchUrlParams =
      new URLSearchParams(fetchSubscriptionPlan).get("plan") || "";
    setUserPlan(searchUrlParams);
  }, [location.search]);

  const onCaptcha = async (value: string) => {
    if (value) {
      setRegisterButtonVisible(true);
    } else if (value === null) {
      setRegisterButtonVisible(true);
      openNotification({message: "Captcha token expired",  description: "Account creation not verified"})
    }
  };

  const openNotification = (props: NotificationProps) => {
    notification.open({
      message: `${props.message}`,
      description: `${props.description}`,
      icon: <Icon type="smile" style={{ color: "#108ee9" }} />,
    });
  };

  const onSignUp = () => {
    setErrors([]); //Reset validation to validate again.
    let errs: Array<string> = [];

    props.form.validateFields((err: any, vals: any) => {
      //Checks for whitespace
      if (vals.first_name.length < 2) {
        errs.push("Input fields cannot be empty");
      }

      if (!err) {
        if (linkPromoCode.length > 0) {
          //Promocode integration
          let params = {
            email: vals.email,
            password: vals.password,
            first_name: vals.first_name,
            last_name: vals.last_name,
            user_role_id: 1,
            promo_code: linkPromoCode,
          };

          props.signUp(params).then((res) => {
            if (res && res.success) {
              openNotification({message: "Email Sent!",  description: "Check your email and verify your account"});
              setTimeout(() => history.push("/"), 3000);

              // set errors
              setErrors(errs);
              // return validation
              if (errs.length < 1) {
                return true;
              } else {
                return false;
              }
            } else {
              message.warning(res.message || "");
            }
          });
        } else {
          let params = {
            email: vals.email,
            password: vals.password,
            first_name: vals.first_name,
            last_name: vals.last_name,
            user_role_id: 1,
            promo_code: "",
          };

          if (
            vals.first_name.length < 2 ||
            vals.email.length < 2 ||
            vals.password.length < 2 ||
            vals.first_name.length < 2 ||
            vals.last_name.length < 2
          ) {
            setRegisterButtonVisible(false);
          }

          if (userPlan === "free") {
            props.signUp(params).then((res) => {
              if (res && res.success) {
                openNotification({message: "Email Sent!",  description: "Check your email and verify your account"});

                setTimeout(() => history.push("/"), 4000);

                setErrors(errs);

                if (errs.length < 1) {
                  return true;
                } else {
                  return false;
                }
              } else {
                message.warning(res.message || "");
              }
            });
          } else if (userPlan === "Basic") {
            props.signUp(params).then((res) => {
              if (res && res.success) {
                setTimeout(
                  () =>
                    (window.location.href = `https://thewritepractice.samcart.com/products/becoming-writer-monthly/?first_name=${vals.first_name}&last_name=${vals.last_name}&email=${vals.email}`),
                  3000
                );
              }
            });
          } else {
            props.signUp(params).then((res) => {
              if (res && res.success) {
                setTimeout(
                  () =>
                    (window.location.href = `https://thewritepractice.samcart.com/products/becoming-writer-premium-monthly/?first_name=${vals.first_name}&last_name=${vals.last_name}&email=${vals.email}`),
                  3000
                );
              }
            });
          }
        }
      }
    });
  };

  const { getFieldDecorator } = props.form;

  return (
    <div className="bg-c1">
      <div className="container inner">
        <Form>
          <div className="section">
            <Row className="t-center">
              <h2>Create your Write Practice account</h2>
              <p>Review your account details and create a password.</p>
            </Row>
            <div className="register-form auth-box">
              <Form.Item label="" style={{ marginBottom: 5 }}>
                {getFieldDecorator("first_name", {
                  rules: rule,
                })(
                  <div className="register-input">
                    <Input
                      type="text"
                      prefix={<Icon type="user" />}
                      placeholder="First Name"
                      id="first_name"
                    />
                  </div>
                )}
              </Form.Item>
              <Form.Item label="" style={{ marginBottom: 5 }}>
                {getFieldDecorator("last_name", {
                  rules: rule,
                })(
                  <div className="register-input">
                    <Input
                      type="text"
                      prefix={<Icon type="user" />}
                      placeholder="Last Name"
                      id="last_name"
                    />
                  </div>
                )}
              </Form.Item>
              <Form.Item className="imp">
                {getFieldDecorator("email", {
                  rules: emailrule,
                })(
                  <div className="register-input">
                    <Input
                      type="email"
                      prefix={<Icon type="mail" />}
                      placeholder="Email"
                      id="email"
                    />
                  </div>
                )}
              </Form.Item>
              <Form.Item className="imp">
                {getFieldDecorator("password", {
                  rules: [
                    { required: true, message: "Please input password" },
                    {
                      min: 5,
                      message: "Password must be more than 6 characters.",
                    },
                  ],
                })(
                  <div className="register-input">
                    <Input.Password
                      placeholder="Password"
                      prefix={<Icon type="lock" />}
                    />
                  </div>
                )}
              </Form.Item>
              {errors.length > 0 ? (
                <Row className="inner">
                  {errors.map((err, i) => (
                    <div key={`err_${i}`} style={{ color: "red" }}>
                      <Alert message={err} type="error" showIcon />
                    </div>
                  ))}
                </Row>
              ) : null}
              <div className="inner" style={{ paddingLeft: "2em" }}>
                <ReCAPTCHA
                  sitekey={process.env.REACT_APP_RECAPTCH_KEY}
                  onChange={onCaptcha}
                />
                <div style={{ paddingTop: "1em" }}>
                  {isRegisterButtonVisible && (
                    <Button
                      type="primary"
                      className="btn-c1"
                      size="large"
                      onClick={onSignUp}
                    >
                      Get Started
                    </Button>
                  )}
                </div>
              </div>

              <div>
                <span>Already a user?</span>
                <Link to="/auth/login"> Login</Link>
              </div>
            </div>
          </div>
        </Form>
      </div>
    </div>
  );
}

const mapStateToProps = (state: {
  errors: Map<string, any>;
  auth: Map<string, any>;
}) => ({
  token: "", //state.auth.get('token'),
});

const Actions = {
  signUp,
  verifyPromoCode,
};

const _Form = connect(mapStateToProps, Actions)(SignUp);

export default Form.create({ name: "signUp" })(_Form);
