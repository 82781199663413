import React from "react";
import {
  Avatar,
  Comment,
  Typography,
  Icon,
  Button,
  Input,
  Popconfirm,
  Row,
  Col,
} from "antd";
import { formatTime } from "../../../utils/helpers";

interface CommentProps {
  children?: any;
  editable?: Boolean;
  onReply: (parent_id: number, text: string) => any;
  onDelete: (id: number) => any;
  onEdit: (id: number, text: string) => void;
  comment: Critique;
  posts: any;
  highlights: (text: string) => void;
}

interface Critique {
  comment: any;
  name: string;
  created_at: string;
  level: number;
  id: number;
  parent_id: number;
  user: {
    user_id: number;
    user_name: string;
    profile_pic: any;
  };
  highlighted_text: string;
}

function Response(props: CommentProps) {
  const [open, toggleComment] = React.useState(false);
  const [edit, toggleEdit] = React.useState(false);
  const [value, setValue] = React.useState("");
  const [reply, setReply] = React.useState("");
  const [activeHightlight, setActiveHightlight] = React.useState<string>("");
  const [confirmDelete, toggleConfirmation] = React.useState(false);

  function wrap(func: any) {
    toggleEdit(false);
    toggleComment(false);
    const ref = () => func;
    ref();
  }

  const action = props.editable
    ? [
      <span onClick={() => toggleEdit(!edit)}>Edit</span>,
      <Popconfirm
        placement="bottom"
        title="Are you sure you want to delete this comment?"
        onConfirm={() => props.onDelete(props.comment.id)}
        onCancel={() => toggleConfirmation(false)}
        okText="Yes"
        cancelText="No"
      >
        <Icon type="delete" />
      </Popconfirm>,
    ]
    // : props.comment.level !== 3
    //   ? [<span onClick={() => toggleComment(!open)}>Reply</span>]
    //   : [null];
    : [
      <span onClick={() => toggleComment(!open)}>Reply</span>,
  ]

  return (
    <Comment
      className="twp-comment"
      content={
        !edit ? (
          <div>
            {props.comment.highlighted_text ? (
              <div className="twp-code">
                <a
                  onClick={() =>
                    props.highlights(props.comment.highlighted_text)
                  }
                >
                  " {props.comment.highlighted_text} "
                </a>
              </div>
            ) : null}
            <pre
              dangerouslySetInnerHTML={{
                __html: value ? value : props.comment.comment,
              }}
            ></pre>
            {/* <pre>{props.comment.comment}</pre> */}
            {/* <Typography.Paragraph>
              {value ? value : props.comment.comment}
            </Typography.Paragraph> */}
          </div>
        ) : (
          <div>
            <textarea
              className="update-text-area"
              onChange={(e) => setValue(e.target.value)}
              autoFocus={edit}
              value={value ? value : props.comment.comment}
            ></textarea>
            <Row>
              <Col span={4}>
                <Button
                  type="primary"
                  onClickCapture={() => wrap(props.onEdit(props.comment.id, value))}
                >
                  Update
            </Button>
              </Col>
              <Col span={12}>
                <Button onClick={() => toggleEdit(false)}>Cancel</Button>
              </Col>
            </Row>
          </div>
        )
      }
      actions={action}
      author={
        <Row type="flex" justify="space-between">
          <Col>
            <a href={`/user/profile/${props.comment.user.user_id}`}>{props.comment.user.user_name}</a>
          </Col>
          <Col>
            <span style={{ paddingLeft: "5px" }}>
              {formatTime(props.comment.created_at)}
            </span>
          </Col>
        </Row>
      }
      avatar={
        props.comment.user && props.comment.user.profile_pic ? (
          <Avatar src={props.comment.user.profile_pic} />
        ) : (
          <Avatar>
            {props.comment.user.user_name &&
              props.comment.user.user_name.length > 0
              ? props.comment.user.user_name[0]
              : ""}
          </Avatar>
        )
      }
    >
      <>
        <>
          {open ? (
            <div>
              <Input.TextArea
                autoSize={{ minRows: 2, maxRows: 10 }}
                onChange={(e) => setReply(e.target.value)}
              ></Input.TextArea>
              <div style={{ padding: 10, textAlign: 'right' }}>
                <Button
                  type="primary"
                  onClick={() => wrap(props.onReply(props.comment.id, reply))}
                >
                  Comment
              </Button>
              </div>
            </div>
          ) : null}
        </>
        <>{props.children}</>
      </>
    </Comment>
  );
}

export default Response;
