import React from "react";
import { Row, Typography, Icon, Col, List, Avatar, Button, Spin } from "antd";

const { Title, Text } = Typography;
const { Item } = List;

function GroupBlock(props: any) {

  const link = `/group/${props.id}`

  return props.loading ? (
    <div className="loading-spin">
      <Spin />
    </div>
  ) : (
    props.isDeleted != "true" ? (
      <div className="post-block inner" key={props.index + "_post"}>
        <Row className="group-inner">
          <Col span={5}>
            <Avatar size={80} src={props.icon} />
          </Col>
          <Col span={14}>
            <a href={link}><Text strong>{props.group_name}</Text></a>
            <br />
            <Text>{props.description}</Text>
          </Col>
          <Col className="group-inner" span={5}>
            <span>
              <Icon type="team" />
              {props.no_of_followers} Writers
          </span>
          </Col>
          <Col>
          </Col>
        </Row>
      </div>
    ) : (
      <div className="deleted_style inner" key={props.index + "_post"}>
        <Row>
          <Col span={5}>
            <Avatar size={80} src={props.icon} />
          </Col>
          <Col span={14}>
            <Text disabled>{props.group_name}</Text>
            <br />
            <Text disabled>{props.description}</Text>

          </Col>
          <Col className="group-inner" span={5}>
            <span>
              <Icon type="team" />
              <Text disabled>{props.no_of_followers} Writers</Text>
            </span>
            <br />
            <Text mark>Deleted</Text>
          </Col>
          <Col>
          </Col>
        </Row>
      </div>
    )

  );
}

export default GroupBlock;
