import React, { useState, Fragment } from "react";
import { connect } from "react-redux";
import { useParams, useHistory, useLocation } from "react-router-dom";
import { Modal, Row, Icon, Button, Drawer, Col, Spin, message, Alert, Tooltip, notification, List } from "antd";
import Highlighter from "react-highlight-pop";
import { find, filter, unionBy } from "lodash";

import PracticeCommentsContainer from "../Practice/PracticeCritiquesContainer";
import EditPracticeContainer from "../Practice/EditPracticeContainer";
import ProfileAvatar from "../../snippets/Avatars/ProfileAvatar";
import MessageEditor from "../../snippets/editors/MessageEditor";
import PracticePath from "../../snippets/Breadcrumb/PracticePath";
import AssignmentContainer from "../Assignments/AssignmentContainer";

import { formatTime } from "../../../utils/helpers";

import { getPWA } from "../../../actions/posts";
import { deletePracticePost, getSinglePracticePost } from "../../../actions/practice";
import { fetchPracticeCritiques, requestAddCritique } from "../../../actions/practice";
import { clearAssignments } from "../../../actions/app";

const { confirm } = Modal;

let generalPost;
let writingAidPost;
var hashResult;
interface CritiqueParams {
  comment: string;
  highlighted_text: string;
  group_id: number;
  parent_id: any;
  post_id: number;
  level: number;
}
interface PWAParams {
  post_id: number,
  pwa_post: string
}

// A custom hook that builds on useLocation to parse the query string for you.
function useQuery(key) {
  return new URLSearchParams(useLocation().search).get(key);
}
interface Props {
  deletePracticePost: (id: number) => void;
  fetchPracticeCritiques: (id: number) => void;
  requestAddCritique: (params: CritiqueParams) => any;
  getPWA: (params: PWAParams) => any;
  posts: any;
  loading: boolean;
  assignments: any;
  highlights?: any;
  clearAssignments: (params: any) => any;
  groups: any;
  practices: any;
  articles: any;
  practiceComments: Array<any>;
  getSinglePracticePost: (id: number) => any;
}

function PracticePostContainer(props: Props) {
  const location = useLocation();
  const { post_id } = useParams();
  const userId = localStorage.getItem("user-id")
  const user_id = localStorage.getItem("twp-user-id");
  const userRole = localStorage.getItem("twp-user-role");

  const parsedId = parseInt(post_id ? post_id : "");

  const [fontsize, setFontSize] = useState<any>(2);
  const [comments, openComments] = useState<boolean>(false);
  const [editor, toggleEditor] = useState<boolean>(false);
  const [addCommentDrawer, toggleAddCommentDrawer] = useState<boolean>(false);
  const [highlight, setHighlight] = useState<string>("");
  const [activeHightlight, setActiveHightlight] = useState<string>("");
  const [hcrit, setHcrit] = useState<string>("");
  const [addLoading, toggleAddLoading] = useState<boolean>(false);
  const [completed, setCompleted] = useState("");
  const history = useHistory();

  const [visible, setModalVisible] = useState(false);
  const [adminAssignments, toggleAssignmentsPanel] = useState<boolean>(false);
  const [highlightedComments, setHighlightedComments] = useState({
    comment: "",
    highlighted_text: "",
  });
  const [pwaOn, setPWAOn] = useState({
    pwa_response: {
      summaries: [{
        summary_items: [{
          category: {
            description: "",
            display_name: "",
            name: ""
          },
          category_name: "",
          text: "",
          sub_items: [{
            search_term: "",
            sub_category: ""
          }]
        }]
      }],
      tags: [{
        end_pos: "",
        category: "",
        hint: "",
        start_pos: "",
        subcategory: "",
        suggestions: [],
        id: ""
      }],
      word_count: ""
    }
  })
  const [aidToggle, setWritingAidToggle] = useState(false); //pwa toggle
  const [post, setPost] = useState({
    title: "",
    post: "",
    name: "",
    post_type: "",
    created_at: "",
    highlighted_text: "",
    feedback: "",
    genre: "",
    genre_id: "",
    word_count: "",
    assignment_completed: "",
    assignments: [{
      group: "",
      post_id: 0,
      title: "",
      user: "",
      completed: ""
    }],
    group: {
      group_id: 1,
      group_name: "",
      group_types: [{
        group_type: "",
        group_type_id: ""
      }],
      group_type_id: 1,
      assignment_type: "",
      assignment_type_id: 1,
    },
    user: {
      user_id: "",
      user_name: "",
      profile_pic: "",
    },
    badges: []
  });
  const query = useQuery("drafts") || "";
  const [draftId, setDraftId] = React.useState<any>(
    query
      ? query
      : Math.random()
        .toString(36)
        .replace(/[^a-z]+/g, "")
        .substr(0, 5)
  );

  const showDeleteConfirm = () => {
    confirm({
      title: "Are you sure you want to delete your writing piece permanently?",
      icon: <Icon type="exclamation-circle" />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        props.deletePracticePost(parseInt(post_id ? post_id : ""));
        message.success("Post Deleted");
        setTimeout(() => history.push("/articles"), 1000);
      },
    });
  };

  const showCommentConfirm = () => {
    toggleAddCommentDrawer(true);
  };

  const openCommentsDrawer = () => {
    setHcrit("");
    toggleAddCommentDrawer(true);
  };

  const handleHighlightCommentSubmit = () => {

    if (!addLoading) {
      toggleAddLoading(true);

      let params = {
        comment: hcrit,
        highlighted_text: highlight,
        parent_id: 0,
        post_id: parsedId,
        group_id: post.group.group_id,
        level: 1,
        comments_array: [post.user.user_id],
      };

      props.requestAddCritique(params).then(() => {
        toggleAddLoading(false);
        toggleAddCommentDrawer(false);
        setActiveHightlight(highlight);
        setHcrit("");
      });

      //commented to avoid the whole page reloading - to avoid this - value property has been added to the MessageEditor
      setTimeout(function () {
        window.location.reload();
      }, 500);
    }
  };

  // To print the post
  function print(a) {
    window.print();
  };

  // Handle when you close the add highlighted comment drawer
  const onHighlightCommentClose = () => {
    setHighlight("");
  };

  React.useEffect(() => {
    what()
    props.fetchPracticeCritiques(parsedId);
    // if (location.state == "commentNotification") {
    //   openComments(true);
    // }
    assignmentCompletion();


  }, [parsedId]);

  const what = async () => {
    let check = await props.getSinglePracticePost(parsedId)
    if (check) {
      setPost(check);
    }
  };

  React.useEffect(() => {
    if (props.practiceComments.length > 0) {
      let p = find(props.practiceComments);
      if (p) {
        setHighlightedComments(p);
      }
    }
  });


  //Gets Clicked Highlight from Comment Component
  function highlightCallBack(text) {
    setActiveHightlight(text);
  }

  const assignmentCompletion = () => {
    var a = post.assignment_completed;
    setCompleted(post.assignment_completed);
  };

  // ADMIN - Clear Assignments
  const clearAssignments = () => {
    confirm({
      title: "Are you sure you want to clear assignements for this post?",
      icon: <Icon type="exclamation-circle" />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        const submit = props.clearAssignments(parsedId);
        submit.then((res) => {
          if (res.success === true) {
            openNotification()
            setTimeout(function () {
              window.location.reload();
            }, 1000);
          } else {
            message.error("Assignments could not be cleared");
          }
        })
      },
    });
  };

  //Cleared Assignment Notification
  const openNotification = () => {
    notification.open({
      message: "Success",
      description: "Assignments cleared!",
      icon: <Icon type="smile" style={{ color: "#108ee9" }} />,
    });
  };

  // writing piece
  generalPost = activeHightlight ? post.post.replace(
    activeHightlight,
    `<span class="twp-highlighter">${activeHightlight}</span>`
  )
    : post.post;

    var middle;

    React.useEffect(() => {
      if (aidToggle === true) {
        getPWAInititation()
      }
    }, [aidToggle === true]);
  
  
    //send post for PWS
    const getPWAInititation = async () => {
      var htmlFreePost = post.post.replace(/<\/?[^>]+(>|$)/g, "");
      let params = {
        post_id: parsedId,
        pwa_post: htmlFreePost
      }
  
      const subs = await props.getPWA(params);
      setPWAOn(subs);
      return subs;
    };
  
    //Writing Aid Toggle
    function writingAidToggle(checked) {
      if (checked == true) {
        setWritingAidToggle(true)
      } else {
        setWritingAidToggle(false)
      }
    }
  
    //triggers pwa integrations
    if (pwaOn && aidToggle == true) {
      showWritingAid()
    }
  
    //PWA Functionality
    function showWritingAid() {
  
      //post without html tags
      var htmlFreePost = post.post.replace(/<\/?[^>]+(>|$)/g, "");
  
      //sorts the tags
      pwaOn.pwa_response.tags.sort(function (a, b) {
        return parseInt(b.end_pos) - parseInt(a.start_pos);
      })
  
      for (var i = 0; i < pwaOn.pwa_response.tags.length; i++) {
        var start = pwaOn.pwa_response.tags[i].start_pos,
          end = pwaOn.pwa_response.tags[i].end_pos + 1,
          before = htmlFreePost.substring(0, parseInt(start)),
          after = htmlFreePost.substring(parseInt(end))
        middle = `${pwaOn.pwa_response.tags[i].category === "grammarspelling" ?
          "<a class='grammar-spelling'  href=#" + `${pwaOn.pwa_response.tags[i].id}` + ">" + pwaOn.pwa_response.tags[i].subcategory + "</a>"
          : "<a class='grammar-grammar'  href=#" + `${pwaOn.pwa_response.tags[i].id}` + ">" + pwaOn.pwa_response.tags[i].subcategory + "</a>"}`;
  
        htmlFreePost = before + middle + after;
      }
      writingAidPost = htmlFreePost;
    }
  
    //hash in url triggers pwa toggle, condition aid toggle prevents popup popping when toggle is closed
    React.useEffect(() => {
      if (location.hash && aidToggle === true) {
        hashResult = location.hash.replace(/^#/, '')
        writingAidPopUp(hashResult)
      }
    });
  
    //removes the hash in url to prevent popup opening when switching the toggle 
    React.useEffect(() => {
      if (aidToggle === false) {
        window.location.hash = ''
      }
    });
  
    //Popup on erros
    function writingAidPopUp(params) {
      let aidArrayFilter = pwaOn.pwa_response.tags.filter((a: any) => a.id === params);
  
      aidArrayFilter.map((x) => {
        Modal.info({
          title: x.hint,
          content: (
            <div>
              <span>Suggestions: {x.suggestions.join(', ')}</span>
            </div>
          ),
          onOk() { },
        });
      })
    }
  
    React.useEffect(() => {
      if (props.groups.length > 0 && userRole === "user") {
        checkGroupAccess();
      }
    }, [post.group.group_id]);
  
    const checkGroupAccess = async () => {
      const grpRestrictions = await props.groups.filter((a: any) => a.id === post.group.group_id);
      if (grpRestrictions.length == 0) {
        setModalVisible(true)
      }
    };

    // Save comments to drafts
  const saveCommentsToDrafts = () => {
    if (hcrit.length > 0) {
      const p: any = [
        {
          user_id: userId,
          id: draftId,
          comment: hcrit,
        },
      ];

      const drfts = localStorage.getItem("twp-comment-drafts") || "";

      const parsed = drfts ? JSON.parse(drfts) : [];

      //Added to remove the posted draft from localstorage
      const com = filter(parsed, (a) => a.id !== localStorage.getItem("twp-draft-posted"));

      let d = unionBy(p, com, "id");

      const drft = localStorage.setItem("twp-comment-drafts", JSON.stringify(d));
      message.success("Saved comment in drafts")
    }

  }

  return props.loading ? (
    <div className="loading-spin">
      <Spin />
    </div>
  ) : (
    // return (
    <Row className="bg-primary bg-c1 bg-with-sidebar">
      <div className="twp-wrapper" id="printableArea">
      <div style={{display: "none"}}>
        <AssignmentContainer/>
        </div>
        <div className="">
          {/* <Hide in="lg"> */}
          <Row className="reader">
            <PracticePath blogPost={post.group.group_name} PracticeName={post.title} />
          </Row>
          <div className="reader">
            <Row type="flex" className="column inner">
              <h1 className="title">{post.title}</h1>
              <Row type="flex" align="middle" justify="space-between">
                <Col className="inner">
                  {post.user.profile_pic === "" ? (
                    <ProfileAvatar
                      size={40}
                      img="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png"
                      title={`<a href="/user/profile/${post.user.user_id}">${post.user.user_name}</a>`}
                      subtitle={`${formatTime(
                        post.created_at
                      )} on <a href="/practice/${post.group.group_id}">${post.group.group_name}</a>`}
                      genre={post.genre}
                      postWordCount={post.word_count}
                    />
                  ) : (
                    <ProfileAvatar
                      size={40}
                      img={post.user.profile_pic}
                      title={`<a href="/user/profile/${post.user.user_id}">${post.user.user_name}</a>`}
                      subtitle={`${formatTime(
                        post.created_at
                      )} on <a href="/practice/${post.group.group_id}">${post.group.group_name
                        }</a>`}
                      genre={post.genre}
                      postWordCount={post.word_count}
                    />
                  )}
                </Col>
                <Col>
                  {post.user.user_id == user_id ? (
                    <Button.Group>
                      {props.practiceComments && props.practiceComments.length > 0 ? (
                        <Button onClick={() => openComments(!comments)}>
                          {props.practiceComments.length} <Icon type="message" />
                        </Button>
                      ) : post.post_type === "Message" ? (
                        <Button
                          onClick={() =>
                            toggleAddCommentDrawer(!addCommentDrawer)
                          }
                        >
                          Reply
                        </Button>
                      ) : (
                        <>
                        <Button
                          onClick={() =>
                            toggleAddCommentDrawer(!addCommentDrawer)
                          }
                          style={{margin: 10}}
                        >
                          Add Critique
                        </Button>
                        </>
                      )
                      }
                      <Tooltip title="Edit">
                        <Button onClick={() => toggleEditor(!editor)}>
                          <Icon type="edit" />
                        </Button>
                      </Tooltip>
                      <Tooltip title="Delete">
                        <Button onClick={showDeleteConfirm}>
                          <Icon type="delete" />
                        </Button>
                      </Tooltip>
                      <Tooltip title="Print">
                        <Button onClick={() => print("printableArea")}>
                          <Icon type="printer" />
                        </Button>
                      </Tooltip>
                    </Button.Group>
                  ) : userRole === "admin" ? (
                    <Button.Group>
                      {props.practiceComments && props.practiceComments.length > 0 ? (
                        <Button onClick={() => openComments(!comments)}>
                          {props.practiceComments.length} <Icon type="message" />
                        </Button>
                      ) : post.post_type === "Message" ? (
                        <Button
                          onClick={() =>
                            toggleAddCommentDrawer(!addCommentDrawer)
                          }
                        >
                          Reply
                        </Button>
                      ) : (
                        <>
                        <Button
                          onClick={() =>
                            toggleAddCommentDrawer(!addCommentDrawer)
                          }
                          style={{margin: 10}}
                        >
                          Add Critique
                        </Button>
                        </>
                      )
                      }
                      <Button onClick={() => toggleEditor(!editor)}>
                        <Icon type="edit" />
                      </Button>
                      <Button onClick={showDeleteConfirm}>
                        <Icon type="delete" />
                      </Button>
                      <Button onClick={() => print("printableArea")}>
                        <Icon type="printer" />
                      </Button>
                      {post.post_type === "WP" ? (
                        <Tooltip title="Assignments">
                          <Button onClick={() => toggleAssignmentsPanel(!adminAssignments)}>
                            <Icon type="alert" />
                          </Button>
                        </Tooltip>
                      ) : null}
                      {/* { post.post_type === "WP" ? (
                        <Tooltip title="Clear Assignments">
                          <Button onClick={clearAssignments}>
                            <Icon type="alert" />
                          </Button>
                        </Tooltip>
                      ) : null} */}
                    </Button.Group>
                  ) : post.user.user_id != user_id ? (
                    <>
                      {props.practiceComments && props.practiceComments.length > 0 ? (
                        <Button onClick={() => openComments(!comments)}>
                          {props.practiceComments.length} <Icon type="message" />
                        </Button>
                      ) : post.post_type === "Message" ? (
                        <Button
                          onClick={() =>
                            toggleAddCommentDrawer(!addCommentDrawer)
                          }
                        >
                          Reply
                        </Button>
                      ) : (
                        <>
                        <Button
                          onClick={() =>
                            toggleAddCommentDrawer(!addCommentDrawer)
                          }
                          style={{margin: 10}}
                        >
                          Add Critique
                        </Button>
                        </>
                      )
                      }
                      <Tooltip title="Print">
                        <Button onClick={() => print("printableArea")}>
                          <Icon type="printer" />
                        </Button>
                      </Tooltip>
                    </>
                  ) : null
                  }
                </Col>
              </Row>
              <Col>
                {post.post_type === "WP" && post.feedback ? (
                  <Alert message={post.feedback} type="info" showIcon />
                ) : null}
              </Col>
            </Row>
            <div className="summary"></div>
            <div className="inner para" style={{ fontSize: fontsize + 9 }}>

              <Highlighter
                id="post_highlighter"
                onHighlightPop={(v) => setHighlight(v)}
                style={{ zIndex: 200 }}
                popoverItems={itemClass => (
                  <Fragment>
                    <span
                      style={{ fontSize: 24 }}
                      className={itemClass}
                      onClick={showCommentConfirm}
                    >
                      <Icon type="message" />
                    </span>
                  </Fragment>
                )}
              >
                {aidToggle == false ? (
                  <div>
                    <p
                      className="post-container"
                      dangerouslySetInnerHTML={{
                        __html: generalPost,
                      }}
                    ></p>

                  </div>
                ) : (
                  <div>
                    {<p
                      dangerouslySetInnerHTML={{
                        __html: writingAidPost,
                      }}
                    ></p>
                    }
                  </div>
                )}
              </Highlighter>

              {/* bottom navigation  */}
              {post.post_type === "WP" ? (
                <Row type="flex" justify="center">
                  <Col className="inner" >
                    {post.user.user_id == user_id ? (
                      <Button.Group>
                        {props.practiceComments && props.practiceComments.length > 0 ? (
                          <Button onClick={() => openComments(!comments)} >
                            {props.practiceComments.length} <Icon type="message" />
                          </Button>
                        ) : null
                        }
                        <Button onClick={() => toggleEditor(!editor)}>
                          <Icon type="edit" />
                        </Button>
                        <Button onClick={showDeleteConfirm}>
                          <Icon type="delete" />
                        </Button>
                        <Button onClick={() => print("printableArea")}>
                          <Icon type="printer" />
                        </Button>
                      </Button.Group>
                    ) : userRole === "admin" ? (
                      <Button.Group>
                        {props.practiceComments && props.practiceComments.length > 0 ? (
                          <Button onClick={() => openComments(!comments)} style={{ width: "auto" }}>
                            {props.practiceComments.length} <Icon type="message" />
                          </Button>
                        ) : null
                        }
                        <Button onClick={() => toggleEditor(!editor)} style={{ width: "auto" }} >
                          <Icon type="edit" />
                        </Button>
                        <Button onClick={showDeleteConfirm} style={{ width: "auto" }}>
                          <Icon type="delete" />
                        </Button>
                        <Button onClick={() => print("printableArea")} style={{ width: "auto" }}>
                          <Icon type="printer" />
                        </Button>
                        {post.post_type === "WP" ? (
                          <Tooltip title="Clear Assignments">
                            <Button onClick={clearAssignments} style={{ width: "auto" }} >
                              <Icon type="alert" />
                            </Button>
                          </Tooltip>
                        ) : null}
                      </Button.Group>
                    ) : post.user.user_id != user_id ? (
                      <>
                        {props.practiceComments && props.practiceComments.length > 0 ? (
                          <Button onClick={() => openComments(!comments)} style={{ width: 150 }}>
                            {props.practiceComments.length} <Icon type="message" />
                          </Button>
                        ) : null
                        }
                        <Button onClick={() => print("printableArea")} style={{ width: 150 }}>
                          <Icon type="printer" />
                        </Button>
                      </>
                    ) : null
                    }
                  </Col>
                </Row>

              ) : null}

              {/* copyright */}
              {post.post_type === "WP" ? (
                <Alert
                  message={`This work is copyrighted by ${post.user.user_name}. Any unauthorized reproduction, alteration, distribution, or other use of this work is prohibited.`}
                  icon={<Icon type="copyright" />}
                  showIcon
                />
              ) : null}
            </div>
          </div>
          {/* </Hide> */}
          {/* Message Redesign */}
          {post.post_type === "Message" ? (
            <div className="reader">
              {/* Add Comments Editor */}
              <Row>
                <MessageEditor value={hcrit} onChange={(v) => setHcrit(v)} />
              </Row>
              <Row className="inner">
                <div style={{ textAlign: "right" }}>
                  <Button
                    type="primary"
                    loading={addLoading}
                    className="btn-c1"
                    htmlType="submit"
                    onClick={handleHighlightCommentSubmit}
                    id="message-reply"
                  >
                    Reply
                  </Button>
                </div>
              </Row>
              {/* {post.user.user_id === user_id  ? ( */}
              <PracticeCommentsContainer
                completed={post.assignment_completed}
                highlights={highlightCallBack}
                grpId={post.group.group_id}
                post={post}
              />
              {/* ) : null } */}
            </div>
          ) : (
            <>
              <Drawer
                title={
                  <Row type="flex" align="middle" justify="space-between">
                    <h3 className="imp">Critiques</h3>
                    <Row gutter={8} type="flex">
                      <Col>
                        <Button
                          type="primary"
                          onClick={() => openCommentsDrawer()}
                        >
                          Add Critique <Icon type="plus" />
                        </Button>
                      </Col>
                      <Col>
                        <Button onClick={() => openComments(false)}>
                          Close
                        </Button>
                      </Col>
                    </Row>
                  </Row>
                }
                placement="right"
                width="700px"
                closable={false}
                onClose={() => openComments(false)}
                visible={comments}
              >
                {post.user.user_id == user_id &&
                  post.assignment_completed === "true" ? (
                  <PracticeCommentsContainer
                    completed={post.assignment_completed}
                    highlights={highlightCallBack}
                    grpId={post.group.group_id}
                    post={post}
                  />
                ) : (
                  <PracticeCommentsContainer
                    completed={post.assignment_completed}
                    highlights={highlightCallBack}
                    grpId={post.group.group_id}
                    post={post}
                  />
                )}
              </Drawer>
              <Drawer
                title="Add Critique"
                placement="right"
                width="600px"
                visible={addCommentDrawer}
                onClose={() => toggleAddCommentDrawer(!addCommentDrawer)}
              >
                {highlight ? (
                  <Row className="inner summary">
                    <Col>
                      <p>"{highlight}"</p>
                    </Col>
                    <Col>
                      <Button onClick={onHighlightCommentClose}>
                        <Icon type="delete" />
                      </Button>
                    </Col>
                  </Row>
                ) : null}
                <Row>
                  <MessageEditor value={hcrit} onChange={(v) => setHcrit(v)} />
                </Row>
                <Row className="inner">
                  {post.post_type === "Message" ? (
                    <Button
                      type="primary"
                      loading={addLoading}
                      className="btn-c1"
                      htmlType="submit"
                      onClick={handleHighlightCommentSubmit}
                    >
                      Reply
                    </Button>
                  ) : (
                    <>
                    <Button
                      type="primary"
                      loading={addLoading}
                      className="btn-c1"
                      htmlType="submit"
                      onClick={handleHighlightCommentSubmit}
                      style={{margin: 10}}
                    >
                      Add critique
                    </Button>
                    <Button onClick={saveCommentsToDrafts}>Save to drafts</Button>
                    </>
                  )}
                </Row>
              </Drawer>
            </>
          )
          }
          {/* End of Message Redesign */}
          <Drawer
            title="Edit your Writing Piece"
            placement="right"
            closable
            width={700}
            onClose={() => toggleEditor(false)}
            visible={editor}
          >
            <EditPracticeContainer post={post} postUserId={post.user.user_id} groupType={post.post_type} groupId={post.group.group_id} />
          </Drawer>
        </div>
        {/* //ADMIN ASSIGNMENT PANEL */}
        <Drawer
          title={
            <Row type="flex" align="middle" justify="space-between">
              <h4 className="imp">{post.group.assignment_type} Assignment</h4>
              <Row gutter={8} type="flex">
                <Col>
                  <Button
                    type="primary"
                    onClick={clearAssignments}
                  >
                    Clear Assignments
                  </Button>
                </Col>
                <Col>
                  <Button onClick={() => toggleAssignmentsPanel(false)}>
                    Close
                  </Button>
                </Col>
              </Row>
            </Row>
          }
          placement="right"
          width="500px"
          closable={false}
          onClose={() => toggleAssignmentsPanel(false)}
          visible={adminAssignments}
        >
          {post.assignments != null ? (
            <List
              itemLayout="horizontal"
              dataSource={post.assignments}
              renderItem={item => (
                <List.Item key={"_assignments"}>
                  <div className={item.completed === "True" ? "active" : ""}>
                    {item.completed === "True" ? (
                      <Icon type="check-circle" />
                    ) : null}{" "}
                    <a
                      // className="link-a"
                      href={`/post/${item.post_id}`}
                    >
                      {item.title}
                    </a>{" "}
                    by <a className="link-a">{item.user}</a> in{" "}
                    <a className="link-a">
                      {item.group}
                    </a>
                  </div>
                </List.Item>
              )}
            />
          ) : (<p>No Assignments</p>)}

        </Drawer>
        {/* <Modal
          visible={visible}
          footer={null}
          maskClosable={false}
          closable={false}
          width={820}
          style={{ top: 250 }}
          bodyStyle={{ height: 400 }}
        >
          <div style={{ paddingTop: 30 }}>
            <Result
              subTitle="Sign up for the appropriate community to view this group."
              extra={[
                <>
                  <Col>
                    <Link to="/">Home</Link>
                  </Col>
                  <Col>
                    <a href={helpDesk} target="_blank">
                      Contact Support
                    </a>
                  </Col>
                </>
              ]}
            />
          </div>
        </Modal> */}
      </div>
    </Row>
  );
}

const Actions = {
  getSinglePracticePost,
  deletePracticePost,
  fetchPracticeCritiques,
  requestAddCritique,
  clearAssignments,
  getPWA
};

const mapStP = (state: any) => ({
  posts: state.posts.list,
  loading: state.posts.loading,
  assignments: state.app.assignments,
  groups: state.groups.list,

  practices: state.practices.practices,
  articles: state.posts.articles,
  practiceComments: state.practiceComments.practiceComments
});

export default connect(mapStP, Actions)(PracticePostContainer);
