import React, { useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { Avatar, Row, Col, Icon, Descriptions, Tag, Skeleton, Button, Menu, Dropdown, Modal, List, Typography, Spin,
  Tabs, message } from "antd";

import { fetchAllUserProfile, followUserProfile } from "../../../../actions/app";
import GeneralUserBlock from "../../../snippets/listing/GeneralUserBlock";
import GeneralPracticeUserBlock from "../../Practice/GeneralPracticeUserBlock"
import GeneralSettingsBody from "../../../shared/GeneralSettingsBody";
import { months } from "../../../../utils/statics";

import { fetchUserPosts } from "../../../../actions/user";
import { fetchUserPracticePosts } from "../../../../actions/practice"
import { fetchUserFollowers, fetchUserFollowing } from "../../../../actions/user";

interface Bio {
  badges: any;
  bio: string;
  books_published: any;
  comments_count: number;
  email: string;
  first_name: string;
  genre: any;
  id: number;
  member_since: string;
  last_name: string;
  posts_count: number;
  profile_pic: any;
  subscription_plan: string;
  user_groups: any;
  user_role: string;
  writing_goal: any;
  website: string;
  isFollowing: string;
}

interface Props {
  posts: Array<any>;
  bio: Bio;
  userId?: any;
  loading: boolean;
  fetchAllUserProfile: (id: number) => any;
  fetchUserPosts: (id: number, offset: number, limit: number) => any;
  fetchUserPracticePosts: (id: number, offset: number, limit: number) => any;
  followUserProfile: (follow_id: number) => any;
  fetchUserFollowers: (user_id: any) => any;
  fetchUserFollowing: (user_id: any) => any;
}

const { TabPane } = Tabs;

function GeneralProfileContainer(props: Props) {
  let site;
  const limit = 10;
  const { posts } = props;
  const { id } = useParams();
  const parsedId = parseInt(id ? id : "");

  const user_id = localStorage.getItem("twp-user-id");

  const [loading, setLoading] = useState(false);
  const [offset, setOffset] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [counter, setCounter] = useState(0);
  const [hide, setHide] = useState(false);
  const userRole = localStorage.getItem("twp-user-role");
  const [userGenProfile, setUserGenProfile] = useState({
    bio: "",
    books_published: "",
    comments_count: "",
    email: "",
    first_name: "",
    id: "",
    isFollowing: "",
    last_name: "",
    member_since: "",
    posts_count: "",
    profile_pic: "",
    user_groups: [
      {
        group_id: "",
        group_name: "",
        group_status: "",
        group_types: "",
      },
    ],
    user_role: "",
    website: "",
    writing_goal: "",
  });

  const d = new Date(userGenProfile.member_since);
  const date = `${months[d.getMonth()]} / ${d.getFullYear()}`;

  const [followers, setUserFollowers] = React.useState<Array<any>>([]);
  const [following, setFollowingList] = React.useState<Array<any>>([]);

  const [visible, setVisible] = useState(false);
  const [folllowingvisible, setFollowingVisible] = useState(false);

  const [followMessage, setFollowMessage] = useState(false);
  const [followingMessage, setFollowingMessage] = useState(false);

  const [pract, setPract] = React.useState<Array<any>>();
  const genId = userGenProfile.id ? userGenProfile.id : null;

  if ( userGenProfile.website && !userGenProfile.website.match(/^(https|http):\/\//g)) {
    site = `//${userGenProfile.website}`;
  }

  React.useEffect(() => {
    const getGenUserProfile = async () => {
      const subs = await props.fetchAllUserProfile(parsedId);
      if (subs) {
        setUserGenProfile(subs);
      } else {
        message.error('Error loading user details', 10);
      }
      return subs;
    };
    getGenUserProfile();
  }, [parsedId]);


  React.useEffect(() => {
    setLoading(true);
    if (counter === 0) {
      setHide(true);
    }
    if (props.bio && props.bio.id) {
      invokeActivityFeed(0);
      invokePracticeFeed(0);
    }
  }, []);

  //Handles Load More button
  const handleLoadMore = () => {
    if (hasMore) {
      invokeActivityFeed(offset + 1).then(() => setOffset(offset + 1));
      setCounter(counter + 1);
      if (counter === 0) {
        setHide(false);
      }
    }
  };

  //Handles Previous button
  const handlePrevious = () => {
    if (hasMore) {
      invokeActivityFeed(offset - 1).then(() => setOffset(offset - 1));

      setCounter(counter - 1);
      if (counter === 1) {
        setHide(true);
      }
    }
  };

  //Invokes Feed
  const invokeActivityFeed = async (offset) => {
    setLoading(true);
    return await props.fetchUserPosts(parsedId, offset, limit).then((res) => {
      if (res) {
        setLoading(false);
        const { posts } = res.data[0];
        const psts = posts

        if (psts && psts.length > limit) {
          setHasMore(false);
        }

      } else {
        message.error('Error loading posts', 10);
      }
    });
  };

  const invokePracticeFeed = async (offset) => {
    setLoading(true);
    return await props.fetchUserPracticePosts(parsedId, offset, limit).then((res) => {
      if (res) {
        setLoading(false);
        const psts = res.data[0].posts;
        setPract(psts);

        if (psts && psts.length > limit) {
          setHasMore(false);
        }

      } else {
        message.error('Error loading practice posts', 10);
      }
    });
  };


  //Handles Follow button
  const followGroup = () => {
    props.followUserProfile(parsedId).then((r) => {
      setTimeout(function () {
        window.location.reload();
      }, 1000);
    });
  };

  React.useEffect(() => {
    getFollowerList()
    getFollowingList()
  }, [genId]);


  const getFollowerList = async () => {
    // people who are following the user
    const followerList = await props.fetchUserFollowers(genId)

    if (followerList) {
      if (followerList.success == false) {
        setFollowMessage(true)
      } else {
        setUserFollowers(followerList.data);
      }
    } 
    // else {
    //   message.error('Error loading follower list', 10);
    // }
    // return followerList;
  }

  //Shows Modal
  const showModal = () => {
    setVisible(true);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const getFollowingList = async () => {
    //people who the user is following
    const followingList = await props.fetchUserFollowing(genId);

    if (followingList) {
      if (followingList.success == false) {
        setFollowingMessage(true)
      } else {
        setFollowingList(followingList.data);
        // return followingList;
      }
    } 
    // else {
    //   message.error('Error loading following list', 10);
    // }
  };

  // Shows Modal
  const showFollowingModal = () => {
    setFollowingVisible(true);
  };

  const handleFollowingCancel = () => {
    setFollowingVisible(false);
  };

  // return posts && posts.length ? (
  return (
    <GeneralSettingsBody active="profile">
      {props.loading ? (
        <div className="loading-spin">
          <Spin />
        </div>
      ) : (
        <div className="inner">
          <Row type="flex" justify="space-between">
            <Col span={8}>
              {props.loading ? (
                <Skeleton loading={props.loading} active className="inner" />
              ) : (
                userGenProfile.profile_pic != "" ? (
                  <Avatar size={120} src={userGenProfile.profile_pic} />
                ) : (
                  <Avatar
                    size={120}
                    src="/assets/twp-logo.jpeg"
                  />
                )
              )}
            </Col>
            <Col span={8}>
              {id !== user_id ? (
                <div>
                  {userGenProfile.isFollowing === "true" ? (
                    <Dropdown.Button
                      trigger={["click"]}
                      overlay={
                        <Menu>
                          <Menu.Item
                            style={{ color: "red" }}
                            onClick={followGroup}
                          >
                            Unfollow
                          </Menu.Item>
                        </Menu>
                      }
                    >
                      {" "}
                      Following <Icon type="check" />
                    </Dropdown.Button>
                  ) : (
                    <Button type="primary" onClick={followGroup}>
                      Follow
                      <Icon type="plus" />
                    </Button>
                  )}
                </div>
              ) : null}
            </Col>
          </Row>
          <br />
          <Row>
            <h2 className="subtitle">
              {userGenProfile.first_name} {userGenProfile.last_name}
            </h2>
            <label>{userGenProfile.bio}</label>
          </Row>
          <br />
          {props.loading ? (
            <Skeleton loading={props.loading} active className="inner" />
          ) : (
            <>
              <Descriptions layout="vertical"
                column={1}
                className="profile-description">
                {userGenProfile.website ? (
                  <Descriptions.Item label="Website">
                    <a className="link gen-profile-font" href={site} target="_blank" rel="noopener noreferrer">
                      {userGenProfile.website}
                    </a>
                  </Descriptions.Item>
                ) : null}
                {userRole == "admin" ? (
                  <Descriptions.Item label="Email">
                    {userGenProfile.email}
                  </Descriptions.Item>
                ) : null}

                <Descriptions.Item label="Member since"> <span className="gen-profile-font">{date}</span></Descriptions.Item>
                <Descriptions.Item label="Critiques" >
                  <span className="gen-profile-font">{userGenProfile.comments_count}</span>
                </Descriptions.Item>
                <Descriptions.Item label="Posts">
                  <span className="gen-profile-font">{userGenProfile.posts_count}</span>
                </Descriptions.Item>
                {userGenProfile.writing_goal ? (
                  <Descriptions.Item label="Writing Goal">
                    <span className="gen-profile-font">{userGenProfile.writing_goal}</span>
                  </Descriptions.Item>
                ) : null}
                {userGenProfile.books_published ? (
                  <Descriptions.Item label="Books Published">
                    <span className="gen-profile-font">{userGenProfile.books_published}</span>
                  </Descriptions.Item>
                ) : null}
                {followMessage == true ? (
                  <Descriptions.Item label="Followers">
                    0
                  </Descriptions.Item>
                ) : <Descriptions.Item label="Followers"><span onClick={showModal} style={{ cursor: "pointer" }}>{followers.length}</span></Descriptions.Item>}

                {followingMessage == true ? (
                  <Descriptions.Item label="Following">
                    0
                  </Descriptions.Item>
                ) : <Descriptions.Item label="Following"><span onClick={showFollowingModal} style={{ cursor: "pointer" }}>{following.length}</span>
                </Descriptions.Item>}
              </Descriptions>

              <Descriptions
                layout="vertical"
                column={1}
                className="profile-description"
              >
                {userGenProfile.user_groups ? (
                  <Descriptions.Item label="Active groups">
                    <div>
                      {userGenProfile.user_groups.length > 0 ? (
                        userGenProfile.user_groups.map((grp, i) => (
                          <div className="profile-tags">
                            <Tag className="twp-bubble" key={"profile_grp_" + i}>
                              <Avatar size="small">{grp.group_name[0]}</Avatar>
                              <span className="twp-bubble-text">
                                {grp.group_name}
                              </span>
                            </Tag>
                          </div>
                        ))
                      ) : (
                        <span>None</span>
                      )}
                    </div>
                  </Descriptions.Item>
                ) : null}
              </Descriptions>
            </>
          )}
          <h3>All posts</h3>

          <Tabs defaultActiveKey="1">
            <TabPane tab="Posts" key="1">
              {posts.length > 0 ? (
                <>
                  <>
                    <List dataSource={posts} renderItem={GeneralUserBlock} />
                    <Skeleton loading={loading} active className="area" />
                  </>

                  {posts.length > 0 ? (
                    <Row type="flex" justify="center">
                      <Col style={{ paddingRight: 10 }}>
                        {posts.length < 10 ? (
                          <Button style={{ display: "none" }} onClick={handleLoadMore}>
                            Load more
                          </Button>
                        ) : (
                          <Button onClick={handleLoadMore}>Load more</Button>
                        )}
                      </Col>
                      <Col>
                        {hide === true ? (
                          <Button style={{ display: "none" }} onClick={handlePrevious}>
                            Previous
                          </Button>
                        ) : (
                          <Button onClick={handlePrevious}>Previous</Button>
                        )}
                      </Col>
                    </Row>
                  ) : null}
                </>
              ) : null}
            </TabPane>
            {pract && pract.length > 0 &&
              <TabPane tab="Practices" key="2">
                {pract && pract.length > 0 && (
                  <>
                    <List dataSource={pract} renderItem={GeneralPracticeUserBlock} />
                    <Skeleton loading={loading} active className="area" />
                  </>
                )}
              </TabPane>
            }

          </Tabs>


          {/* ) : (
          <Empty
            image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
            imageStyle={{
              height: 60,
            }}
            description={
              <Text style={{ fontSize: 12 }} type="secondary"> No posts from user</Text>
            }
          >
          </Empty>
        )} */}
        </div>

      )}
      <Modal
        title={"Followers"}
        visible={visible}
        onCancel={handleCancel}
        footer={null}
      >
        <Row style={{ paddingBottom: 10 }}>
          <div>
            <Col span={18}>
              <List
                itemLayout="horizontal"
                dataSource={followers}
                renderItem={(a: any, index) => (
                  <List.Item key={index + "_followers"}>
                    <List.Item.Meta
                      avatar={<Avatar src={a.profile_pic} />}
                      title={
                        <a href={`/user/profile/${a.id}`}>{a.followers}</a>
                      }
                    />
                    <div>

                    </div>
                  </List.Item>
                )}
              />
            </Col>
          </div>
        </Row>
      </Modal>
      <Modal
        title={"Following"}
        visible={folllowingvisible}
        onCancel={handleFollowingCancel}
        footer={null}
      >
        <Row style={{ paddingBottom: 10 }}>
          <div>
            <Col span={18}>
              <List
                itemLayout="horizontal"
                dataSource={following}
                renderItem={(a: any, index) => (
                  <List.Item key={index + "_following"}>
                    <List.Item.Meta
                      avatar={<Avatar src={a.profile_pic} />}
                      title={
                        <a href={`/user/profile/${a.id}`}>{a.following}</a>
                      }
                    />
                    <div>
                    </div>
                  </List.Item>
                )}
              />
            </Col>
          </div>
        </Row>
      </Modal>
    </GeneralSettingsBody>
  );
}

const mapStoP = (state: any) => ({
  bio: state.user.bio,
  posts: state.user.posts,
  loading: state.posts.loading,
});

const Actions = {
  fetchAllUserProfile,
  fetchUserPosts,
  followUserProfile,
  fetchUserFollowers,
  fetchUserFollowing,
  fetchUserPracticePosts
};

export default connect(mapStoP, Actions)(GeneralProfileContainer);
