import React, { useState } from "react";
import { Tabs, Button, Row, Col, Select, message, Popover, Typography, notification, Modal } from "antd";
import { filter } from "lodash";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";

import MessageEditor from "../../snippets/editors/MessageEditor";
import WritingPieceEditor from "../../snippets/editors/WritingPieceEditor";
import { invokeCreatePost } from "../../../actions/posts";
import { fetchAllGroups } from "../../../actions/groups";

const { TabPane } = Tabs;

const { Text } = Typography;

interface CreatePostProps {
  invokeCreatePost: (body: any) => any;
  fetchAllGroups: any;
  loading: boolean;
  groups: any;
  groupId: any;
  groupType: any;
  subcriptionExpired?;
  grpWordCount?: any;
}

function CreatePostContainer(props: CreatePostProps) {
  const [tab, setTab] = React.useState("1"); // '1' : Writing Piece, '2' : Message
  const [wp, setWp] = React.useState({
    title: "",
    post: "",
    feedback: "",
    summary: "",
    genre_id: 1,
    word_count: ""
  });
  const [msg, setMsg] = React.useState("");
  const history = useHistory();
  const [group, setGroup] = useState(1);
  const [errors, setErrors] = React.useState<Array<string>>([]);
  const [wordCount, setWordCount] = useState("");

  const validate = () => {
    setErrors([]); //Reset validation to validate again.
    let errs: Array<string> = [];
    const text = wp.post.replace(/<[^>]*>/g, "");

    //VALIDATE
    if (props.groupType.length > 1) {
      if (wp && tab === "1") {
        //No title
        if (!wp.title)
          errs.push("You need enter a title for your writing piece");

        if (!text.trim()) errs.push("You're writing piece is empty");
        //if post is too long
        if (text.trim().split(/\s+/).length > parseInt(wordCount))
          errs.push(
            "You're writing piece is too long. You cannot post anymore than " +
            parseInt(wordCount) +
            " words"
          );

        //if no group is selected
        if (!group) errs.push("Select a group to push the post to");
      } else {
        //else messages
        //no group
        if (!group) errs.push("Select a group to push the post to");

        //no message
        if (!msg) errs.push("you cannot leave message as empty");
        if (msg.length < 2) errs.push("Your message is empty");
      }

    } else {
      //one group type

      if (msg) {
        if (!group) errs.push("Select a group to push the post to");
        if (!msg) errs.push("you cannot leave message as empty");
        if (msg.length < 2) errs.push("Your message is empty");
      } else if (msg.length == 0 && wp.post.length > 0) {

        if (!wp.title)
          errs.push("You need enter a title for your writing piece");

        if (!text.trim()) errs.push("You're writing piece is empty");

        //if post is too long
        if (text.trim().split(/\s+/).length > parseInt(wordCount))
          errs.push(
            "You're writing piece is too long. You cannot post anymore than " +
            parseInt(wordCount) +
            " words"
          );
        //if no group is selected
        if (!group) errs.push("Select a group to push the post to");
      }
    }

    setErrors(errs); // set errors
    // return validation
    if (errs.length < 1) return true;
    return false;
  };

  const handleSubmit = (e: any) => {

    if (props.groupType.length > 1) {
      if (tab === "1") {
        let wpParams = {
          title: wp.title,
          post: wp.post,
          summary: wp.summary,
          group_id: props.groupId,
          genre_id: wp.genre_id,
          feedback: wp.feedback,
          post_type_id: 1,
          word_count: wp.word_count
          // pwa_post: wp.pwa_post
        }

        const isvalid = validate();
        if (isvalid) {
          const submit = props.invokeCreatePost(wpParams)
          submit.then((id: any) => {

            if (id) {
              if (tab === "1") {
                setTimeout(() => assignementsNotifiction("info"), 2000);
              }
              history.push(`/post/${id}`);
            } else {
              Modal.error({
                title: "Couldn't save post",
                content: (
                  <div>
                    <h4>Please make sure your piece does not contain emojis. Also try copy-and-pasting from a plain text word processor which may remove formatting that interferes with our editor. If you continue to have issues, contact our help desk.</h4>
                    <a target="_blank" href='https://thewritepractice.zendesk.com/hc/en-us/requests/newhttps://www.atticus.io/troubleshooting-synching-errors/' rel="noopener noreferrer">
                      https://thewritepractice.zendesk.com/hc/en-us/requests/new
                    </a>
                  </div>
                )
              });
            }

            // setTimeout(() => assignementsNotifiction("info"), 1500);
            // if (id) history.push(`/post/${id}`);
            // else message.info(`Couldn't save post`);
          });
        }
      } else {
        let msgParams = {
          title: msg,
          post: "",
          summary: "",
          feedback: "",
          group_id: props.groupId,
          post_type_id: 2,
          // pwa_post: msg
        }
        const isvalid = validate();
        if (isvalid) {
          const submit = props.invokeCreatePost(msgParams)
          submit.then((id: any) => {

            if (id) {
              history.push(`/post/${id}`);
            } else {
              Modal.error({
                title: "Couldn't save post",
                content: (
                  <div>
                    <h4>Please make sure your piece does not contain emojis. Also try copy-and-pasting from a plain text word processor which may remove formatting that interferes with our editor. If you continue to have issues, contact our help desk.</h4>
                    <a target="_blank" href='https://thewritepractice.zendesk.com/hc/en-us/requests/newhttps://www.atticus.io/troubleshooting-synching-errors/' rel="noopener noreferrer">
                      https://thewritepractice.zendesk.com/hc/en-us/requests/new
                    </a>
                  </div>
                )
              });
            }


            // if (id) history.push(`/post/${id}`);
            // else message.info(`Couldn't save post`);
          });
        }
      }
    } else {
      if (msg && wp.post.length == 0) {
        let msgParams = {
          title: msg,
          post: "",
          summary: "",
          feedback: "",
          group_id: props.groupId,
          post_type_id: 2,
          word_count: wp.word_count
        }
        const isvalid = validate();
        if (isvalid) {
          const submit = props.invokeCreatePost(msgParams)
          submit.then((id: any) => {

            if (id) {
              history.push(`/post/${id}`);
            } else {
              Modal.error({
                title: "Couldn't save post",
                content: (
                  <div>
                    <h4>Please make sure your piece does not contain emojis. Also try copy-and-pasting from a plain text word processor which may remove formatting that interferes with our editor. If you continue to have issues, contact our help desk.</h4>
                    <a target="_blank" href='https://thewritepractice.zendesk.com/hc/en-us/requests/newhttps://www.atticus.io/troubleshooting-synching-errors/' rel="noopener noreferrer">
                      https://thewritepractice.zendesk.com/hc/en-us/requests/new
                    </a>
                  </div>
                )
              });
            }


            // if (id) history.push(`/post/${id}`);
            // else message.info(`Couldn't save post`);
          });
        }

      } else if (msg.length == 0 && wp.post.length > 0) {
        let wpParams = {
          title: wp.title,
          post: wp.post,
          summary: wp.summary,
          group_id: props.groupId,
          genre_id: wp.genre_id,
          feedback: wp.feedback,
          post_type_id: 1,
          word_count: wp.word_count
        }
        const isvalid = validate();
        if (isvalid) {
          const submit = props.invokeCreatePost(wpParams)
          submit.then((id: any) => {

            if (id) {
              if (tab === "1") {
                setTimeout(() => assignementsNotifiction("info"), 1500);
              }
              history.push(`/post/${id}`);
            } else {
              Modal.error({
                title: "Couldn't save post",
                content: (
                  <div>
                    <h4>Please make sure your piece does not contain emojis. Also try copy-and-pasting from a plain text word processor which may remove formatting that interferes with our editor. If you continue to have issues, contact our help desk.</h4>
                    <a target="_blank" href='https://thewritepractice.zendesk.com/hc/en-us/requests/newhttps://www.atticus.io/troubleshooting-synching-errors/' rel="noreferrer">
                      https://thewritepractice.zendesk.com/hc/en-us/requests/new
                    </a>
                  </div>
                )
              });
            }

            // setTimeout(() => assignementsNotifiction("info"), 1500);
            // if (id) history.push(`/post/${id}`);
            // else message.info(`Couldn't save post`);
          });
        }
      }
    }
  }

  React.useEffect(() => {
    const cs = filter(props.groups, (c) => c.id === props.groupId);
    const word = cs.map((a) => {
        setWordCount(a.word_count);
    });
  });


  //Subscription Expired Popover over Submit Button
  const content = (
    <div>
      <Text mark>Please renew your subscription</Text>
    </div>
  );

    //Assignment Alert
    const assignementsNotifiction = (type) => {
      notification[type]({
        message: "New Assignment",
        description:
          "Now that you’ve posted your writing piece, it’s time to critique three pieces by other writers. Check the assignment box for your critique assignment. Happy critiquing!",
          duration: 15,
      });
    };

  return (
    <div>
      <Row type="flex" justify="space-between" align="middle">
        <Col>
          <h3>Get started here</h3>
        </Col>
        <Col>
          {props.subcriptionExpired === "true" ? (
            <Popover content={content} title="Subscription Expired" trigger="hover">
              <Button type="primary" onClick={handleSubmit} loading={props.loading} disabled>
                Submit
          </Button>
            </Popover>
          ) : (
              <Button type="primary" onClick={handleSubmit} loading={props.loading}>
                Submit
              </Button>
            )}
        </Col>
      </Row>
      {errors.length > 0 ? (
        <Row className="inner">
          {errors.map((err, i) => (
            <div key={`err_${i}`} style={{ color: "red" }}>
              <b>* {err}</b>
            </div>
          ))}
        </Row>
      ) : null}
      {props.groupType.length > 1 ? (
        <Tabs
          // defaultActiveKey="2"
          animated={false}
          onChange={setTab}
          onTabClick={() => setErrors([])}
        >
          <TabPane tab="Writing piece" key="1">
            <WritingPieceEditor
              onChange={setWp}
              mini={true}
              word_count={wordCount}
            />
          </TabPane>
          <TabPane tab="Message" key="2">
            <MessageEditor value={msg} onChange={setMsg} />
          </TabPane>
        </Tabs>
      ) : (
          <Tabs
            // defaultActiveKey="2"
            animated={false}
            onChange={setTab}
            onTabClick={() => setErrors([])}
          >
            {props.groupType.map((a, i) => (
              a.group_type == "WP" ? (
                <TabPane tab="Writing piece">
                  <>
                    <WritingPieceEditor
                      onChange={setWp}
                      mini={true}
                      word_count={wordCount}
                    />
                  </>
                </TabPane>
              ) : (
                  <TabPane tab="Message" >
                    <MessageEditor value={msg} onChange={setMsg} />
                  </TabPane>
                )
            ))}
          </Tabs>
        )
      }
    </div>
  );
}

const mapStP = (state: any) => ({
  loading: state.posts.loading,
  groups: state.groups.list,
});

const Actions = {
  invokeCreatePost,
  fetchAllGroups,
};

export default connect(mapStP, Actions)(CreatePostContainer);
